<template>
    <div class="modal" style="display: block;">
        <!-- DEPART -->
        <div class="modal-content" v-if="!success">
            <!-- MESSAGE DEPART -->
            <h2 class="title" v-if="!hasError">ANATOMIE FONCTIONNELLE DU GENOU <br>
            <span class="subtitle">Glissez et déposez les éléments au bon endroit</span></h2>

            <!-- MESSAGE RESULTATS KO -->
            <p v-if="hasError">ANATOMIE FONCTIONNELLE DU GENOU<br>
            <span class="red">Il semblerait qu’il y ait une ou plusieurs mauvaises réponses.</span></p>

            <!-- DRAG & DROP -->
            <div id="ddzone" class="dragdrop">
                <div class="handle q1" id="qcartilage" :class="{'selected' : false, 'right': isWellPlaced('cartilage')}" data-rep="cartilage">Cartilage</div>
                <div class="handle q2" id="qanterior" :class="{'selected' : false, 'right': isWellPlaced('anterior')}" data-rep="anterior">Ligament croisé antérieur</div>
                <div class="handle q3" id="qmeniscus" :class="{'selected' : false, 'right': isWellPlaced('meniscus')}" data-rep="meniscus">Ménisque</div>
                <div class="handle q4" id="qposterior" :class="{'selected' : false, 'right': isWellPlaced('posterior')}" data-rep="posterior">Ligament croisé postérieur</div>
                <div class="handle q5" id="qmedial" :class="{'selected' : false, 'right': isWellPlaced('medial')}" data-rep="medial">Ligament collatéral médial</div>
                <div class="handle q6" id="qfibula" :class="{'selected' : false, 'right': isWellPlaced('fibula')}" data-rep="fibula">Fibula</div>
                <div class="handle q7" id="qtibia" :class="{'selected' : false, 'right': isWellPlaced('tibia')}" data-rep="tibia">Tibia</div>
                <div class="handle q8" id="qfemur" :class="{'selected' : false, 'right': isWellPlaced('femur')}" data-rep="femur">Femur</div>

                <div class="dropzone">
                    <!-- zone drop left -->
                    <ul class="drop">
                        <li id="r1" class="r1"></li>
                        <li id="r2" class="r2"></li>
                        <li id="r3" class="r3"></li>
                        <li id="r4" class="r4"></li>
                    </ul>

                    <div class="visuel">
                        <img src="img/ressources/ressource-genou.jpg" alt="Genou">
                    </div>

                    <!-- zone drop right -->
                    <ul class="drop drop2">
                        <li id="r5" class="r5"></li>
                        <li id="r6" class="r6"></li>
                        <li id="r7" class="r7"></li>
                        <li id="r8" class="r8"></li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- RESULTATS BONS -->
        <div class="modal-content bravo" v-if="success">
            <!-- MESSAGE RESULTATS OK -->
            <h2>BRAVO !</h2>
            <p>Vous avez mis tous les éléments au bon endroit</p>

            <!-- VISUEL OK -->
            <div class="visuel">
                <img src="img/ressources/ressource-genou-result.jpg" alt="Anatomie Genou">
            </div>
        </div>

        <!-- MODAL FOOTER -->
        <div class="modal-footer">
            <!-- BTN VALIDER -->
            <button type="button" class="btn btn-blue align-center" @click="validate()" v-if="!success"><span>Valider</span></button>

            <button v-if="hasError === false && !isAlreadyInDossier" type="button" class="btn btn-blue align-center" @click="addToDossier()"><span>Ajouter aux ressources</span></button>

            <!-- BTN SUIVANT - AFFICHER UNE FOIS QUE LE USER A VALIDE CETTE ETAPE POUR PASSER A LA PROCHAINE -->
            <button v-if="hasError === false && isAlreadyInDossier" type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>
        </div>
    </div>
</template>


<script>
import jsonQuiz from '@/data/quiz.json'
import { gsap, TweenLite } from "gsap";
import { Draggable } from "gsap/Draggable";
// import { EventBus } from '@/core/event-bus.js';

export default {
    name: 'QuizDragDropGenou',
    data() {
        return {
            quizList: jsonQuiz,
            currentQuestion: 0, // on a un seul quiz de ce type

            lastPos: {x:0,y:0},
            currentHandle : null,
            success: false,
            good_reponses : {
                1 : "femur",
                2 : "posterior",
                3 : "meniscus",
                4 : "fibula",
                5 : "cartilage",
                6 : "medial",
                7 : "anterior",
                8 : "tibia",
            },
            validated_reponses : [],
            reponses : {
                1 : null,
                2 : null,
                3 : null,
                4 : null,
                5 : null,
                6 : null,
                7 : null,
                8 : null,
            },
            // fullImage: false,
            hasError: null,
            isAlreadyInDossier: false,
        }
    },
    mounted(){
        gsap.registerPlugin(Draggable);
        // Amount to offset control points
        this.handles = document.querySelectorAll(".handle");

        Draggable.create(this.handles, {
            type:"x,y",
            //onDrag: this.updatePath,
            bounds:"#ddzone",
            inertia:true,
            edgeResistance:0.65,
            onDragEnd:(e) => {
                if(e.target.classList && e.target.classList.contains('handle')) {

                    for(let i=1; i<9; i++){
                        if(this.reponses[i] == e.target.dataset.rep){
                            this.reponses[i] = null
                        }
                    }
                    if(Draggable.hitTest(document.getElementById('r1'), e.target) && this.reponses[1] == null) {
                        this.reponses[1] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r1').offsetLeft , y: document.getElementById('r1').offsetTop - e.target.offsetTop});
                    } else if(Draggable.hitTest(document.getElementById('r2'), e.target) && this.reponses[2] == null) {
                        this.reponses[2] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r2').offsetLeft , y:document.getElementById('r2').offsetTop - e.target.offsetTop});
                    } else if(Draggable.hitTest(document.getElementById('r3'), e.target) && this.reponses[3] == null) {
                        this.reponses[3] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r3').offsetLeft , y:document.getElementById('r3').offsetTop - e.target.offsetTop});
                    } else if(Draggable.hitTest(document.getElementById('r4'), e.target) && this.reponses[4] == null) {
                        this.reponses[4] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r4').offsetLeft , y:document.getElementById('r4').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r5'), e.target) && this.reponses[5] == null) {
                        this.reponses[5] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r5').offsetLeft , y:document.getElementById('r5').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r6'), e.target) && this.reponses[6] == null) {
                        this.reponses[6] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r6').offsetLeft , y:document.getElementById('r6').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r7'), e.target) && this.reponses[7] == null) {
                        this.reponses[7] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r7').offsetLeft , y:document.getElementById('r7').offsetTop- e.target.offsetTop });
                    } else if(Draggable.hitTest(document.getElementById('r8'), e.target) && this.reponses[8] == null) {
                        this.reponses[8] = e.target.dataset.rep
                        TweenLite.to(e.target,1,{ x:document.getElementById('r8').offsetLeft , y:document.getElementById('r8').offsetTop- e.target.offsetTop });
                    } else {
                        TweenLite.to(e.target,1,{ x:0 , y:0 });
                    }
                }
            }
        });


    },
    computed: {
        current() {
            return this.quizList[this.currentQuestion]
        },
    },
    methods : {
        close() {
            this.$emit('close')
        },
        addToDossier() {
            this.$store.commit("addToDossier", {dossier: this.current.ajoutDossier.dossier, item: this.current.ajoutDossier.item})
            this.isAlreadyInDossier = true
        },
        validate() {
            if (this.reponses[1] == "femur"
                && this.reponses[2] == "posterior"
                && this.reponses[3] == "meniscus"
                && this.reponses[4] == "fibula"
                && this.reponses[5] == "cartilage"
                && this.reponses[6] == "medial"
                && this.reponses[7] == "anterior"
                && this.reponses[8] == "tibia"
            ) {
                this.validated_reponses = ["femur", "posterior", "meniscus", "fibula", "cartilage", "medial", "anterior", "tibia"]
                this.success = true;
                this.hasError = false
            } else {
                for (let i=1; i<9; i++) {
                    if (this.reponses[i] == this.good_reponses[i]) {
                        if (!this.validated_reponses.includes(this.reponses[i])) {
                            this.validated_reponses.push(this.reponses[i])
                        }
                    } else {
                        TweenLite.to(document.getElementById('q' + this.reponses[i]),1,{ x:0 , y:0 });
                        this.reponses[i] = null
                    }
                }

                this.hasError = true;
            }
        },

        isWellPlaced(rep){
            for(let i=1; i<9; i++){
                if(this.validated_reponses.includes(rep)){
                    return true;
                }
            }
            return false;
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

.modal{
  pointer-events: auto;
}

.dragdrop {
    position: relative;

    .handle {
        position: absolute;
        top: 0;
        background: white;
        border: 1px solid black;
        border-radius: 3rem;
        color: black;
        cursor: move;
        display: block;
        font-size: 1.3rem;
        font-weight: 500;
        padding: 0.6rem 1rem;
        text-align: center;

        &.right {
            background: $green;
            border-color: $green;

            &:hover {
                background: darken($green, 15%);
            }
        }
    }

    .q1 {
        top: 0;
    }

    .q2{
        top: 5rem;
    }
    .q3{
        top: 10rem;
    }
    .q4{
        top: 15rem;
    }
    .q5{
        top: 20rem;
    }
    .q6{
        top: 25rem;
    }
    .q7{
        top: 30rem;
    }
    .q8{
        top: 35rem;
    }

    .dropzone {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 22rem;
    }

    .drop {
        padding-top: 10rem;
        flex-basis: 20.5rem;
        min-width: 20.5rem;

        li {
            background: rgba($grey, 0.5);
            border-radius: 3rem;
            height: 3rem;
            line-height: 3rem;
            margin-bottom: 3.9rem;
        }

        &.drop2 {
            padding-top: 15.5rem;

            li {
                margin-bottom: 3.1rem;
            }
        }
    }
}
</style>